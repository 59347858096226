<template>
  <vue-dropzone
    id="multi"
    ref="dropzone"
    class="box"
    :options="dropOptions"
    :use-custom-slot="true"
    :include-styling="false"
    @vdropzone-processing="updateStatus('processing')"
    @vdropzone-success="updateStatus('completed')"
    @vdropzone-error="handleError"
    @vdropzone-complete="clear"
    @vdropzone-total-upload-progress="setProgress"
    @vdropzone-file-added="track('add_file')"
  >
    <div v-if="isWaiting">
      <div class="field">
        <span class="icon is-large has-text-grey-dark animated pulse infinite">
          <i class="fal fa-2x fa-file-times" />
        </span>
        <p>
          <span class="is-size-5 has-text-weight-bold has-text-grey-dark">
            Drag and drop a spreadsheet
          </span>
          <a
            class="icon is-size-6 has-text-grey-dark"
            :title="multiUploadTooltip"
            v-tippy="{ placement: 'right' }"
          >
            <i class="fal fa-info-circle" />
          </a>
        </p>
      </div>
      <button class="button is-info is-small is-rounded">Or browse</button>
    </div>
    <div v-else-if="isProcessing">
      <span class="icon is-medium has-text-grey animated pulse infinite">
        <i class="fal fa-2x fa-cauldron" />
      </span>
      <p class="title is-5 has-text-grey">Uploading</p>
    </div>
    <div v-else-if="isComplete">
      <span class="icon is-medium has-text-soft">
        <i class="fal fa-2x fa-check" />
      </span>
      <p class="title is-5 has-text-soft">Upload complete</p>
      <p class="button is-text has-text-primary">Click to upload again</p>
    </div>
  </vue-dropzone>
</template>

<script>
import { node } from '@/plugins/axios'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { formatDate } from '@/utils/date.utils.ts'
import VueDropzone from 'vue2-dropzone'

import { logOut } from 'modules/auth/services'
export default {
  name: 'MultiUpload',
  data() {
    return {
      progress: null,
      dropOptions: {
        url: process.env.VUE_APP_MULTI_URL + '/headercheck',
        headers: {
          Authorization: node.defaults.headers.common['Authorization'],
          'X-Environment': process.env.VUE_APP_MODE,
          'X-Client': node.defaults.headers.common['X-CLIENT']
        },
        previewTemplate: '<div></div>',
        maxFilesize: 5,
        acceptedFiles: '.xlsx',
        success: (file, response) => this.handleHeaders(response),
        error: (file, error) => {
          this.updateStatus('waiting')
          if (error === 'Unauthorised') {
            logOut()
            this.$notify('You have been logged out')
            this.loggedIn = false
          }
        },
        renameFile: file =>
          `${formatDate(new Date(), 'dd-MM-yyyy-H:mm:ss')}-${file.name}`
      },
      headers: null,
      fileName: null,
      loggedIn: true
    }
  },
  components: { VueDropzone },
  computed: {
    ...mapGetters('multi', ['isWaiting', 'isProcessing', 'isComplete']),
    multiUploadTooltip: () =>
      'For best results please do not apply any special formatting to your spreadsheet prior to upload e.g. coloured cells or fonts'
  },
  methods: {
    ...mapMutations('multi', ['setProgress']),
    ...mapActions('multi', ['updateStatus', 'addHeaders', 'setFileName']),
    handleError(err) {
      if (this.loggedIn) {
        if (err.size > 5000000) {
          this.track('failed_size', err.size)
          this.$notify(
            'Currently we only accept up to 5MB, try splitting the files up'
          )
        } else if (err.type === 'application/vnd.ms-excel') {
          this.track('failed_size', err.type)
          this.$notify(
            'Please save your spreadsheet as a .xlsx (or convert it) rather than an .xls'
          )
        } else {
          this.track('failed_unknown')
          this.$notify('Something went wrong during upload')
        }

        this.updateStatus('waiting')
      }
    },
    handleHeaders(response) {
      this.addHeaders(response.headers)
      if (Array.isArray(response.headers)) {
        this.$modal.open('multi/MultiHeaders', response.fileName)
      } else {
        this.$notify('Something went wrong during upload')
      }
    },
    track(action, detail) {
      this.$mxp.track(`multi_${action}`, { detail })
    },
    clear() {
      this.$refs.dropzone.removeAllFiles()
    }
  }
}
</script>

<style lang="sass">
#multi > .dz-preview
  position: absolute
  background: #fff
  height: 100%
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  transition: all .2s ease
#multi > .dz-preview .dz-image > div
  width: inherit
  height: inherit
  border-radius: 50%
  background-size: contain
#multi > .dz-preview .dz-image > img
  width: 100%
  #multi > .dz-preview .dz-details
    color: white
    transition: opacity .2s linear
#multi > .dz-success-mark, .dz-error-mark, .dz-remove
  display: none
.dz-message
  cursor: default
.dz-clickable
  display: flex
  justify-content: center
  min-height: 100%
  align-items: center
  text-align: center
  padding: 1.5rem
  background: repeating-linear-gradient(45deg, #fff, #fff 10px, #fff 10px, #f9f9f9 20px)
</style>
